import * as i from 'types';
import * as React from 'react';

import { useDevice, useScrollTo } from 'hooks';
import { convertStringToPlainText } from 'services';
import { HeaderReadMore } from 'common/interaction';
import { Heading, RichTextBlock } from 'common/typography';
import { HomeHeaderButton } from 'modules/homeHeader';

import {
  HomeHeaderContainer,
  HomeHeaderVideo,
  HomeHeaderContent,
  HomeHeaderButtons,
  HomeHeaderContentWrapper,
  HomeHeaderDescription,
  HomeHeaderLogoWhite,
  HomeHeaderLogoNavy,
} from './styled';

export const HomeHeader = ({ data, firstSection }: HomeHeaderProps) => {
  const { scrollTo } = useScrollTo();
  const { isMobile } = useDevice();
  const scrollOffset = isMobile ? 100 : 200;

  return (
    <HomeHeaderContainer>
      <HomeHeaderVideo>
        <video muted autoPlay loop playsInline>
          <source
            src={data.fields.video?.fields.file?.url}
            type={data.fields.video?.fields.file?.contentType}
          />
        </video>
      </HomeHeaderVideo>
      <HomeHeaderContentWrapper>
        <HomeHeaderContent>
          <HomeHeaderLogoWhite />
          <HomeHeaderLogoNavy />
          <Heading as="h4" margin="0 0 48px">
            {data.fields.subtitle}
          </Heading>
          <HomeHeaderDescription>
            <RichTextBlock data={data.fields.description} />
          </HomeHeaderDescription>
          <HomeHeaderButtons>
            {data.fields.buttons?.map((button, index) => {
              if (button.fields.anchor)
                return (
                  <HomeHeaderButton
                    {...{ button, index }}
                    key={button.sys.id}
                    onClick={() =>
                      scrollTo(
                        `#${convertStringToPlainText(button.fields.anchor || '')}`,
                        scrollOffset,
                      )
                    }
                  />
                );

              return <HomeHeaderButton {...{ button, index }} key={button.sys.id} />;
            })}
          </HomeHeaderButtons>
        </HomeHeaderContent>
      </HomeHeaderContentWrapper>
      <HeaderReadMore selector={firstSection} scrollOffset={scrollOffset} />
    </HomeHeaderContainer>
  );
};

type HomeHeaderProps = {
  data: i.TypeHeaderHome;
  firstSection: string;
};
