import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import LogoIcon from 'vectors/logo.svg';
import LogoWhiteIcon from 'vectors/logo_white.svg';

export const HomeHeaderContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  z-index: 1;

  ${media.desktop`
    aspect-ratio: 16 / 9;
    padding-top: 0;
    max-height: 100vh;
    overflow: hidden;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.88%, rgba(0, 0, 0, 0.4) 100%);
    }
  `}
`;

export const HomeHeaderVideo = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 46.88%, rgba(0, 0, 0, 0.4) 100%);
  }

  & video {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  ${media.desktop`
    &:after {
      content: none;
    }
  `}
`;

export const HomeHeaderContentWrapper = styled.div`
  margin-top: -48px;

  ${media.desktop`
    max-width: 1513px;
    width: 100%;
    height: calc(100% - 144px);
    padding: 0 48px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  `}
`;

export const HomeHeaderContent = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    padding: 24px;
    margin: 0 24px;

    & > h1 {
      display: none;
    }

    ${media.tablet`
      & > h1 {
        display: block;
      }

      & > h2 {
        display: none;
      }
    `}

    ${media.desktop`
      position: absolute;
      background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2));
      border: 1px solid rgba(255, 255, 255, 0.2);
      box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(32px);
      width: 50%;
      top: 50%;
      transform: translateY(-50%);
      max-width: 640px;
      margin: 0;

      & > h1,
      & > h2,
      & > h4,
      & > p {
        color: ${theme.colors.white};
      }
    `}
  `,
);

export const HomeHeaderLogoWhite = styled(LogoWhiteIcon)`
  width: 288px;
  margin-bottom: 24px;
  display: none;

  ${media.desktop`
    display: block;
  `}
`;

export const HomeHeaderLogoNavy = styled(LogoIcon)`
  width: 192px;
  margin-bottom: 24px;

  ${media.desktop`
    display: none;
  `}
`;

export const HomeHeaderDescription = styled.div(
  ({ theme }) => css`
    margin: 0 0 48px;

    & p {
      ${media.desktop`
        color: ${theme.colors.white};
      `}
    }
  `,
);

export const HomeHeaderButtons = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  & > div {
    width: 100%;
  }

  ${media.tablet`
    display: flex;
    flex-direction: row;
  `}
`;
