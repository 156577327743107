import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ToolsItemTag = styled.div(
  ({ theme }) => css`
    border-radius: 8px;
    padding: 0 12px;
    border: 1px solid ${theme.colors.babyBlue};
    color: ${theme.colors.babyBlue};
    font-size: 14px;
    line-height: 22px;
  `,
);

export const ToolsItemButton = styled.div`
  width: 100%;
  padding: 0 24px 24px;
  margin-top: auto;

  & button {
    width: 100%;
  }
`;

export const ToolsItemContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;

    &:nth-of-type(3n + 2) {
      ${ToolsItemTag} {
        border-color: ${theme.colors.aquaGreen};
        color: ${theme.colors.aquaGreen};
      }

      ${ToolsItemButton} {
        button {
          background-color: ${theme.colors.aquaGreen};

          @media (hover: hover) {
            &:hover {
              background-color: ${theme.colors.aquaGreen60};
            }
          }
        }
      }
    }

    &:nth-of-type(3n + 3) {
      ${ToolsItemTag} {
        border-color: ${theme.colors.pomeloRed};
        color: ${theme.colors.pomeloRed};
      }

      ${ToolsItemButton} {
        button {
          background-color: ${theme.colors.pomeloRed};

          @media (hover: hover) {
            &:hover {
              background-color: ${theme.colors.pomeloRed60};
            }
          }
        }
      }
    }
  `,
);

export const ToolsItemHeader = styled.div`
  ${media.desktop`
    display: flex;
    padding: 24px;
  `}
`;

export const ToolsItemImage = styled.div<ToolsItemImageProps>(
  ({ backgroundImageUrl }) => css`
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: 144px;
    width: 100%;

    ${media.desktop`
      width: 112px;
      height: 112px;
      border-radius: 8px;
      flex-shrink: 0;
    `}
  `,
);

type ToolsItemImageProps = {
  backgroundImageUrl: string;
};

export const ToolsItemMeta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;

  ${media.desktop`
    padding: 0 0 0 24px;
  `}
`;

export const ToolsItemDescription = styled.div`
  padding: 0 24px 24px;
`;
