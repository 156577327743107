import styled, { css } from 'styled-components';

import { Group } from 'common/layout';

export const ConversationStarterInviteBlockContainer = styled.div(
  ({ theme }) => css`
    padding: 24px;
    background-color: ${theme.colors.white};
    border-radius: 8px;
    height: auto;

    ${Group} > button {
      width: 100%;
    }
  `,
);
