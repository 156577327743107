import * as i from 'types';
import * as React from 'react';

import { Accordion } from 'common/interaction';

export const AccordionBlockInformation = ({
  items,
  defaultValue,
}: AccordionBlockInformationProps) => {
  return (
    <Accordion.Root defaultValue={defaultValue}>
      {items?.map((item, index) => (
        <Accordion.Item
          key={item.sys.id}
          isRichTextContent
          index={index}
          item={{
            title: item.fields.title,
            description: item.fields.description,
            id: item.sys.id,
            subtitle: item.fields.subtitle,
          }}
        />
      ))}
    </Accordion.Root>
  );
};

type AccordionBlockInformationProps = {
  items: i.TypeComponentAccordionBlockFields['items'];
  defaultValue?: string;
};
