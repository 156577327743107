import * as i from 'types';
import * as React from 'react';
import * as CFRichTextTypes from '@contentful/rich-text-types';
import { Element } from 'react-scroll'

import { convertStringToPlainText } from 'services';
import { Heading, RichTextBlock } from 'common/typography';

import { PageSectionContentHeader } from './styled';

export const PageSectionContent: React.FC<PageSectionContentProps> = ({
  title,
  description,
  children,
  sectionAnchor,
  hideTitle,
  headerWidth,
}) => {
  const getAnchor = () => {
    if (sectionAnchor) return convertStringToPlainText(sectionAnchor.fields.title);
    if (title) return convertStringToPlainText(title);

    return undefined;
  };

  return (
    <Element as="div" name={getAnchor() || ''} id={getAnchor()}>
      {!hideTitle && (title || description) && (
        <PageSectionContentHeader {...{headerWidth}}>
          <div>
            {title && <Heading margin="0 0 48px">{title}</Heading>}
            {description && <RichTextBlock data={description} />}
          </div>
        </PageSectionContentHeader>
      )}
      {children}
    </Element>
  );
};

type PageSectionContentProps = {
  hideTitle?: boolean;
  title?: string;
  description?: CFRichTextTypes.Block | CFRichTextTypes.Inline;
  sectionAnchor?: i.TypeSectionAnchor;
  headerWidth?: 'auto' | 'half';
};
