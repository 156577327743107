import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const PageSectionContentHeader = styled.div<PageSectionContentHeaderProps>(
  ({ headerWidth }) => css`
    margin-bottom: 40px;
    max-width: ${headerWidth === 'half' ? 'auto' : '864px'};

    & > h1 {
      word-break: break-word;
    }
    
    ${headerWidth === 'half' && css`
      ${media.desktop`
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 24px;
      `}
    `}
  `,
);

type PageSectionContentHeaderProps = {
  headerWidth?: 'auto' | 'half';
};
