import * as i from 'types';
import * as React from 'react';

import { Image } from 'common/general';
import { ContentBlockWithLinksItem } from 'modules/contentBlocks';

export const ContentBlockWithLinks = ({ data }: ContentBlockWithLinksProps) => {
  return (
    <>
      <div>
        {data.fields.links?.map((link, index) => (
          <ContentBlockWithLinksItem item={link} key={link.sys.id} index={index} />
        ))}
      </div>
      {data.fields.image?.fields.file && data.fields.image?.fields.title && (
        <Image
          src={data.fields.image?.fields.file?.url}
          alt={data.fields.image?.fields.title}
          margin="32px 0 0"
          borderRadius={8}
        />
      )}
    </>
  );
};

type ContentBlockWithLinksProps = {
  data: i.TypeComponentContentBlockWithLinks;
};
