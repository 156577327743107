import styled, { css } from 'styled-components';

import { getBrandingColor } from 'services';
import { BrandingTriangle } from 'common/general';

export const TabsVerticalBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabsVerticalBlockNav = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 273px;
  width: 100%;
  flex-shrink: 0;
  gap: 8px;
`;

export const TabsVerticalBlockNavItem = styled.button<TabsVerticalBlockNavItemProps>(
  ({ theme, isActive, index }) => css`
    border: 0;
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: transparent;
    padding: 16px 0;
    cursor: pointer;

    & > p {
      transition: color 0.15s ease;
      color: ${isActive ? getBrandingColor(index) : theme.colors.nightBlue};
    }

    @media (hover: hover) {
      &:hover {
        & > p {
          color: ${getBrandingColor(index)};
        }
      }
    }

    &:first-of-type {
      padding-top: 8px;
    }

    ${BrandingTriangle} {
      transition: opacity 0.15s ease;
      opacity: ${isActive ? 1 : 0};
    }
  `,
);

type TabsVerticalBlockNavItemProps = {
  isActive?: boolean;
  index: number;
};

export const TabsVerticalBlockDescription = styled.div`
  width: 100%;
  max-width: 408px;
`;
