import styled from 'styled-components';

import { media } from 'styles/utils';

export const ConversationStarterInviteBlockFormButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0 0;

  & > button {
    width: 100%;

    & + button {
      margin: 0;
    }
  }

  ${media.large`
    flex-direction: row;
  `}
`;
