import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ConversationStarterInvitePreviewContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.hcsBlue};
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 24px;

    & > button {
      width: 100%;
    }
  `,
);

export const ConversationStarterInvitePreviewProfile = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 100%;
    height: 48px;
    width: 48px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    display: none;

    ${media.tablet`
      display: flex;
    `}

    & svg {
      fill: ${theme.colors.nightBlue};
      height: 24px;
      width: 24px;
    }
  `,
);

export const ConversationStarterInvitePreviewMeta = styled.div`
  width: 100%;
`;
