import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlockWithReviewsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  `}

  ${media.desktop`
    grid-gap: 48px;
  `}
`;
