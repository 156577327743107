import * as i from 'types';
import * as React from 'react';

import { ConversationStarterInviteStepperStep } from 'modules/conversationStarterInvite';

import { ConversationStarterInviteStepperContainer } from './styled';

export const ConversationStarterInviteStepper = ({
  steps,
}: ConversationStarterInviteStepperProps) => {
  return (
    <ConversationStarterInviteStepperContainer>
      {steps?.map((step, index) => (
        <ConversationStarterInviteStepperStep step={step} index={index + 1} key={step.sys.id} />
      ))}
    </ConversationStarterInviteStepperContainer>
  );
};

type ConversationStarterInviteStepperProps = {
  steps: i.TypeHeaderConversationStarterFields['steps'];
};
