import * as i from 'types';
import * as React from 'react';

import { useDocumentScrollThrottled } from 'hooks';
import LogoIcon from 'vectors/logo.svg';
import LogoWhiteIcon from 'vectors/logo_white.svg';
import { MainFooter, Header, MainNavigation, Container } from 'common/layout';

import { PageLayoutContainer, PageLayoutLogo } from './styled';

const PageLayout: React.FC<PageLayoutProps> = ({
  children,
  navigation,
  footer,
  withoutContainer,
  withoutPadding,
  withTransparentHeader,
}) => {
  const [isNavigationOpen, setIsNavigationOpen] = React.useState(false);
  const isScrolled = useDocumentScrollThrottled();
  const isHeaderTransparent = withTransparentHeader && !isScrolled && !isNavigationOpen;

  return (
    <>
      <Header isTransparent={isHeaderTransparent}>
        <PageLayoutLogo to="/">
          <LogoIcon />
          <LogoWhiteIcon />
        </PageLayoutLogo>
        <MainNavigation
          {...{ navigation, isNavigationOpen, setIsNavigationOpen }}
          isTransparent={isHeaderTransparent}
        />
      </Header>
      <PageLayoutContainer
        withoutPadding={withoutPadding}
        withTransparentHeader={withTransparentHeader}
      >
        {withoutContainer ? children : <Container>{children}</Container>}
      </PageLayoutContainer>
      <MainFooter footer={footer} />
    </>
  );
};

type PageLayoutProps = {
  navigation: i.TypeNavigationList;
  footer: i.TypeLayoutFooter;
  withoutContainer?: boolean;
  withoutPadding?: boolean;
  withTransparentHeader?: boolean;
};

export default PageLayout;
