import * as i from 'types';
import * as React from 'react';

import { BrandingTriangle, Image } from 'common/general';
import { Link } from 'common/interaction';
import { Paragraph } from 'common/typography';

import { ContentBlockWithLinksItemContainer } from './styled';

export const ContentBlockWithLinksItem = ({ item, index }: ContentBlockWithLinksItemProps) => {
  const href = item.fields.url
    ? item.fields.url
    : item.fields.eMail
    ? `mailto:${item.fields.eMail}`
    : `tel:${item.fields.phoneNumber}`;

  return (
    <ContentBlockWithLinksItemContainer>
      {item.fields.image?.fields.file?.url ? (
        <Image
          src={item.fields.image.fields.file?.url}
          alt={item.fields.image.fields.title || item.fields.title}
        />
      ) : (
        <BrandingTriangle index={index} />
      )}
      <div>
        <Paragraph margin="0" weight={900}>
          {item.fields.title}
        </Paragraph>
        <Link href={href} to={href}>
          {item.fields.label || item.fields.eMail || item.fields.phoneNumber}
        </Link>
      </div>
    </ContentBlockWithLinksItemContainer>
  );
};

type ContentBlockWithLinksItemProps = {
  index: number;
  item: i.TypeComponentLinkItem;
};
