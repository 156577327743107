import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ThemeVideoBlockVideoContainer = styled.div`
  width: 100%;
  position: relative;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 56.25%;
`;

export const ThemeVideoBlockVideo = styled.iframe`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0;

  & video {
    border-radius: 8px;
  }
`;

export const ThemeVideoBlockContent = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    ${media.desktop`
      border-bottom-left-radius: 0;
      border-top-right-radius: 8px;
    `}
  `,
);

export const ThemeVideoBlockContentTitle = styled.div(
  ({ theme }) => css`
    padding: 24px;
    border-bottom: ${theme.border.default};
  `,
);

export const ThemeVideoBlockContentText = styled.div`
  padding: 24px;
`;
