import styled from 'styled-components';

import { media } from 'styles/utils';

export const ThemeHeaderContent = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: column;
  padding: 96px 0;
  position: relative;

  ${media.large`
    padding: 192px 0;
    flex-direction: row;
  `}
`;

export const ThemeHeaderMeta = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.large`
    width: 408px;
    flex-shrink: 0;
  `}
`;

export const ThemeHeaderDescription = styled.div`
  width: 100%;

  ${media.desktop`
    width: 604px;
  `}

  ${media.large`
    width: 100%;
  `}
`;

export const ThemeHeaderVideoDesktop = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
