import * as React from 'react';

import PersonIcon from 'vectors/person.svg';
import { Button, Modal } from 'common/interaction';
import { Divider, Group } from 'common/layout';
import { Paragraph } from 'common/typography';

import {
  ConversationStarterInvitePreviewContainer,
  ConversationStarterInvitePreviewMeta,
  ConversationStarterInvitePreviewProfile,
} from './styled';

export const ConversationStartInvitePreview = ({
  setOpen,
  isOpen,
}: ConversationStarterInvitePreviewProps) => {
  return (
    <Modal title="Voorbeeld" $withScroll setOpen={setOpen} open={isOpen}>
      <ConversationStarterInvitePreviewContainer>
        <Paragraph margin="0 0 24px" weight={900}>
          Leidinggevende nodigt je uit voor Gespreksstarter
        </Paragraph>
        <Group gap={16} align="flex-start">
          <ConversationStarterInvitePreviewProfile>
            <PersonIcon />
          </ConversationStarterInvitePreviewProfile>
          <ConversationStarterInvitePreviewMeta>
            <Paragraph weight={900} margin="0">
              Goed Gezien
            </Paragraph>
            <Paragraph margin="0">noreply@goedgezien.nl</Paragraph>
            <Divider />
            <Paragraph margin="0">Aan: Medewerker</Paragraph>
          </ConversationStarterInvitePreviewMeta>
        </Group>
        <Paragraph margin="24px 0">
          Hi! Ik vind deze tool zelf erg handig om wat goede vragen aan mezelf te stellen, maar ook
          om eens te kijken welke vragen wij samen nog kunnen bespreken. Zou je hem willen invullen?
          Dan praten we er later samen over verder.
          <br />
          <br />
          De Gespreksstarter toont je in ca. 2 minuten welke vragen voor jou belangrijk zijn om bij
          stil te staan. Of nog beter, welke handig zijn om eens bespreken met je leidinggevende of
          een collega. De Gespreksstarter gebruik je altijd anoniem, je resultaten worden nooit met
          je leidinggevende gedeeld. Alvast bedankt!
        </Paragraph>
        <Button isDisabled>Start</Button>
      </ConversationStarterInvitePreviewContainer>
    </Modal>
  );
};

type ConversationStarterInvitePreviewProps = {
  setOpen: (open: boolean) => void;
  isOpen: boolean;
};
