import * as i from 'types';
import * as React from 'react';

import { ContentRichTextBlock } from 'modules/contentBlocks';

import { ContentBlockWithColumnsContainer } from './styled';

export const ContentBlockWithColumns = ({ data }: ContentBlockWithColumnsProps) => {
  return (
    <ContentBlockWithColumnsContainer>
      {data.fields.columns?.map((column) => (
        <ContentRichTextBlock data={column} key={column.sys.id} />
      ))}
    </ContentBlockWithColumnsContainer>
  );
};

type ContentBlockWithColumnsProps = {
  data: i.TypeComponentContentBlockWithColumns;
};
