import * as i from 'types';
import * as React from 'react';

import { Heading, RichTextBlock } from 'common/typography';
import { ContactBlock } from 'modules/contentBlocks';

import { ContentBlockWithSidebarContainer, ContentBlockWithSidebarSidebar } from './styled';

export const ContentBlockWithSidebar = ({ data }: ContentBlockWithSidebarProps) => {
  return (
    <>
      <Heading margin="0 0 48px">{data.fields.title}</Heading>
      <ContentBlockWithSidebarContainer>
        <RichTextBlock data={data.fields.description} />
        {data.fields.sidebar && data.fields.sidebar.length > 0 && (
          <ContentBlockWithSidebarSidebar>
            {data.fields.sidebar?.map((item) => {
              switch (item.sys.contentType.sys.id) {
                case 'componentContactBlock':
                  return <ContactBlock data={item} key={item.sys.id} />;
                default:
                  return null;
              }
            })}
          </ContentBlockWithSidebarSidebar>
        )}
      </ContentBlockWithSidebarContainer>
    </>
  );
};

type ContentBlockWithSidebarProps = {
  data: i.TypeComponentContentBlockWithSidebar;
};
