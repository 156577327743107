import * as i from 'types';
import * as React from 'react';

import { StatisticsItem } from 'modules/contentBlocks';

import { ContentBlockWithStatisticsContainer } from './styled';

export const ContentBlockWithStatistics = ({ data }: ContentBlockWithStatisticsProps) => {
  return (
    <ContentBlockWithStatisticsContainer>
      {data.fields.statistics?.map((item) => (
        <StatisticsItem item={item} key={item.sys.id} />
      ))}
    </ContentBlockWithStatisticsContainer>
  );
};

type ContentBlockWithStatisticsProps = {
  data: i.TypeComponentContentBlockWithStatistics;
};
