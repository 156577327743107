import * as i from 'types';
import * as React from 'react';

import {
  isTypeAccordionBlock,
  isTypeContentBlockWithLinks,
  isTypeContentBlockWithGrid,
  isTypeContentBlockWithStatistics,
  isTypeContentBlockWithImage,
  isTypeContentBlockWithColumns,
  isTypeRichText,
  isTypeContentBlockWithSidebar,
  isTypeContentBlockWithReviews,
  isTypeQuoteBlock,
} from 'services';
import { Container } from 'common/layout';
import { RichTextBlock } from 'common/typography';
import {
  AccordionBlock,
  ContentBlockWithLinks,
  ContentBlockWithGrid,
  ContentBlockWithStatistics,
  ContentBlockWithImage,
  ContentBlockWithColumns,
  ContentBlockWithSidebar,
  ContentBlockWithReviews,
  QuoteBlock,
} from 'modules/contentBlocks';
import { PageSectionContent } from 'modules/page';

import { PageBodyContainer, PageBodySection } from './styled';

export const PageBody = ({ fields }: PageBodyProps) => {
  return (
    <PageBodyContainer>
      {fields.body?.map((block, index) => {
        const sectionAnchor = block.fields.sectionSettings.fields.sectionAnchor;
        const isWithHeaderSectionContent =
          isTypeAccordionBlock(block) ||
          isTypeContentBlockWithLinks(block) ||
          isTypeContentBlockWithGrid(block) ||
          isTypeContentBlockWithStatistics(block) ||
          isTypeContentBlockWithColumns(block);
        const isWithoutHeaderSectionContent =
          isTypeRichText(block) ||
          isTypeContentBlockWithImage(block) ||
          isTypeContentBlockWithSidebar(block) ||
          isTypeContentBlockWithReviews(block) ||
          isTypeQuoteBlock(block);
        const sectionWidth =
          block.fields.sectionSettings.fields.width === 'small' ? '960px' : '1513px';

        return (
          <PageBodySection
            key={block.sys.id}
            $backgroundColor={block.fields.sectionSettings.fields.backgroundColor || 'white'}
            isFirstOfNewColorSection={
              index === 0 ||
              fields.body?.[index - 1].fields.sectionSettings.fields.backgroundColor !==
                block.fields.sectionSettings.fields.backgroundColor
            }
            isLastOfCurrentColorSection={
              index === Number(fields.body?.length) - 1 ||
              fields.body?.[index + 1]?.fields.sectionSettings.fields.backgroundColor !==
                block.fields.sectionSettings.fields.backgroundColor
            }
            isOnlyContentBlock={fields.body?.length === 1}
          >
            {isWithoutHeaderSectionContent && (
              <Container width={sectionWidth}>
                <PageSectionContent
                  title={block.fields.title}
                  sectionAnchor={sectionAnchor}
                  hideTitle
                >
                  {isTypeRichText(block) && <RichTextBlock data={block.fields.text} />}
                  {isTypeContentBlockWithImage(block) && <ContentBlockWithImage data={block} />}
                  {isTypeContentBlockWithSidebar(block) && <ContentBlockWithSidebar data={block} />}
                  {isTypeContentBlockWithReviews(block) && <ContentBlockWithReviews data={block} />}
                  {isTypeQuoteBlock(block) && <QuoteBlock data={block} />}
                </PageSectionContent>
              </Container>
            )}
            {isWithHeaderSectionContent && (
              <Container width={sectionWidth}>
                <PageSectionContent
                  title={block.fields.title}
                  description={block.fields.description}
                  sectionAnchor={sectionAnchor}
                  headerWidth={block.fields.sectionSettings.fields.headerWidth}
                >
                  {isTypeAccordionBlock(block) && <AccordionBlock data={block} />}
                  {isTypeContentBlockWithLinks(block) && <ContentBlockWithLinks data={block} />}
                  {isTypeRichText(block) && <RichTextBlock data={block.fields.text} />}
                  {isTypeContentBlockWithGrid(block) && <ContentBlockWithGrid data={block} />}
                  {isTypeContentBlockWithStatistics(block) && (
                    <ContentBlockWithStatistics data={block} />
                  )}
                  {isTypeContentBlockWithColumns(block) && <ContentBlockWithColumns data={block} />}
                </PageSectionContent>
              </Container>
            )}
          </PageBodySection>
        );
      })}
    </PageBodyContainer>
  );
};

type PageBodyProps = {
  fields: i.TypePageGeneralFields;
};
