import * as i from 'types';
import * as React from 'react';

import { BrandingTriangle } from 'common/general';
import { Paragraph, RichTextBlock } from 'common/typography';

import {
  TabsVerticalBlockContainer,
  TabsVerticalBlockDescription,
  TabsVerticalBlockNav,
  TabsVerticalBlockNavItem,
} from './styled';

export const TabsVerticalBlock = ({ data }: TabsVerticalBlockProps) => {
  const [selectedItem, setSelectedItem] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (data && data.length > 0) {
      setSelectedItem(data[0].sys.id);
    }
  }, [data?.length]);

  return (
    <TabsVerticalBlockContainer>
      <TabsVerticalBlockNav>
        {data?.map((item, index) => (
          <TabsVerticalBlockNavItem
            key={item.sys.id}
            onClick={() => setSelectedItem(item.sys.id)}
            isActive={item.sys.id === selectedItem}
            index={index}
          >
            <BrandingTriangle index={index} />
            <Paragraph weight={900} margin="0">
              {item.fields.title}
            </Paragraph>
          </TabsVerticalBlockNavItem>
        ))}
      </TabsVerticalBlockNav>
      <TabsVerticalBlockDescription>
        <RichTextBlock
          data={data?.find((item) => item.sys.id === selectedItem)?.fields.description}
        />
      </TabsVerticalBlockDescription>
    </TabsVerticalBlockContainer>
  );
};

type TabsVerticalBlockProps = {
  data: i.TypeComponentAccordionBlockFields['items'];
};
