import * as i from 'types';
import * as React from 'react';

import { Image } from 'common/general';
import { Heading, Paragraph } from 'common/typography';
import { ContentBlockWithLinksItem } from 'modules/contentBlocks';

import { ContactBlockContainer, ContactBlockImage, ContactBlockContent } from './styled';

export const ContactBlock = ({ data }: ContactBlockProps) => {
  return (
    <ContactBlockContainer>
      {data.fields.image?.fields.file && (
        <ContactBlockImage>
          <Image
            src={data.fields.image.fields.file.url}
            alt={data.fields.image.fields.title || data.fields.image.fields.file.fileName}
          />
        </ContactBlockImage>
      )}
      <ContactBlockContent>
        <Heading as="h4" margin="0 0 24px">
          {data.fields.title}
        </Heading>
        <Paragraph margin="0">{data.fields.description}</Paragraph>
        {data.fields.items?.map((link, index) => (
          <ContentBlockWithLinksItem item={link} key={link.sys.id} index={index} />
        ))}
      </ContactBlockContent>
    </ContactBlockContainer>
  );
};

type ContactBlockProps = {
  data: i.TypeComponentContactBlock;
};
