import * as i from 'types';
import * as React from 'react';

import { isExternalUrl } from 'services';
import ChevronDownIcon from 'vectors/chevron_down.svg';
import ChevronRightIcon from 'vectors/chevron_right.svg';
import { Paragraph } from 'common/typography';

import { HomeHeaderButtonContainer } from './styled';

export const HomeHeaderButton = ({ button, index, onClick }: HomeHeaderButtonProps) => {
  const isLink = button.fields.link && !button.fields.anchor;

  return (
    <HomeHeaderButtonContainer
      variant={index === 1 ? 'success' : 'primary'}
      icon={button.fields.anchor ? <ChevronDownIcon /> : <ChevronRightIcon />}
      iconPosition="right"
      buttonType="link"
      justifyContent="space-between"
      alignItems="flex-start"
      onClick={onClick}
      href={
        isLink && button.fields.link
          ? isExternalUrl(button.fields.link)
            ? button.fields.link
            : `/${button.fields.link}`
          : ''
      }
    >
      <Paragraph weight={900} color="white" margin="0">
        {button.fields.title}
      </Paragraph>
      <Paragraph color="white" margin="0" size={14}>
        {button.fields.subtitle}
      </Paragraph>
    </HomeHeaderButtonContainer>
  );
};

type HomeHeaderButtonProps = {
  button: i.TypeComponentButton;
  index: number;
  onClick?: () => void;
};
