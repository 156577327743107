import * as i from 'types';
import * as React from 'react';

import { Heading, RichTextBlock } from 'common/typography';

export const ContentRichTextBlock = ({ data }: ContentRichTextBlockProps) => {
  return (
    <div>
      <Heading as="h4" margin="0 0 40px">
        {data.fields.title}
      </Heading>
      <RichTextBlock data={data.fields.text} />
    </div>
  );
};

type ContentRichTextBlockProps = {
  data: i.TypeComponentRichText;
};
