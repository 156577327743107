import * as i from 'types';
import * as React from 'react';

import QuotationMarkIcon from 'vectors/quotation_mark.svg';
import { Heading, Paragraph } from 'common/typography';

import { QuoteBlockContainer } from './styled';

export const QuoteBlock = ({ data }: QuoteBlockProps) => {
  return (
    <QuoteBlockContainer>
      <QuotationMarkIcon />
      <Heading as="h4" margin="48px 0 24px" align="center">
        {data.fields.title}
      </Heading>
      <Paragraph margin="0">{data.fields.writer}</Paragraph>
    </QuoteBlockContainer>
  );
};

type QuoteBlockProps = {
  data: i.TypeComponentQuoteBlock;
};
