import * as i from 'types';
import * as React from 'react';

import { Button } from 'common/interaction';
import { Group } from 'common/layout';
import { Heading, RichTextBlock } from 'common/typography';
import { useDevice } from 'hooks';

import {
  ToolsItemContainer,
  ToolsItemDescription,
  ToolsItemHeader,
  ToolsItemImage,
  ToolsItemMeta,
  ToolsItemTag,
  ToolsItemButton,
} from './styled';

export const ToolsItem = ({ data }: ToolsItemProps) => {
  const { isMobile } = useDevice()
  
  const downloadUrl = data.fields.download?.fields.file?.url;
  const desktopBackgroundImage = data.fields.desktopImage?.fields.file?.url;
  const mobileBackgroundImage = data.fields.mobileImage?.fields.file?.url;
  const backgroundImage = (isMobile && mobileBackgroundImage) ? mobileBackgroundImage : desktopBackgroundImage;
  // Check which background image should be used based on user device.
  // The desktop image is an existing field, so it is used as a fallback.
  // If there is no mobile image uploaded, the desktop image is used on mobile.

  return (
    <ToolsItemContainer>
      <ToolsItemHeader>
        {backgroundImage && (
          <ToolsItemImage backgroundImageUrl={backgroundImage} />
        )}
        <ToolsItemMeta>
          <Heading as="h3" margin="0">
            {data.fields.title}
          </Heading>
          <Group gap={16} flexWrap>
            {data.fields.tags?.map((tag) => (
              <ToolsItemTag key={tag}>{tag}</ToolsItemTag>
            ))}
          </Group>
        </ToolsItemMeta>
      </ToolsItemHeader>
      <ToolsItemDescription>
        <RichTextBlock data={data.fields.description} />
      </ToolsItemDescription>
      <ToolsItemButton>
        <Button
          href={downloadUrl && `https:${downloadUrl}`}
          buttonType="link"
          isExternalHref
          isDisabled={!downloadUrl}
        >
          {downloadUrl ? (data.fields.downloadButtonLabel || 'Download') : 'Niet beschikbaar'}
        </Button>
      </ToolsItemButton>
    </ToolsItemContainer>
  );
};

type ToolsItemProps = {
  data: i.TypeComponentToolsItem;
};
