import * as i from 'types';
import * as React from 'react';

import {
  isTypeHeaderHome,
  isTypeHeaderTheme,
  isTypeHeaderConversationStarter,
  convertStringToPlainText,
} from 'services';
import { ConversationStarterInviteHeader } from 'modules/conversationStarterInvite';
import { HomeHeader } from 'modules/homeHeader';
import { ThemeHeader } from 'modules/theme';

export const PageHeader = ({ data }: PageHeaderProps) => {
  if (!data?.header) return null;
  const firstSection = convertStringToPlainText(
    data.body?.[0].fields.sectionSettings.fields.sectionAnchor?.fields.title ||
      data.body?.[0].fields.title ||
      '',
  );

  return (
    <>
      {isTypeHeaderHome(data.header) && (
        <HomeHeader data={data.header} firstSection={firstSection} />
      )}
      {isTypeHeaderTheme(data.header) && (
        <ThemeHeader data={data.header} firstSection={firstSection} />
      )}
      {isTypeHeaderConversationStarter(data.header) && (
        <ConversationStarterInviteHeader data={data.header} />
      )}
    </>
  );
};

type PageHeaderProps = {
  data: i.TypePageGeneralFields;
};
