import * as i from 'types';
import * as React from 'react';
import { VisibilityContext, ScrollMenu } from 'react-horizontal-scrolling-menu';

import { useDevice } from 'hooks';
import { convertStringToPlainText } from 'services';
import ArrowLeft from 'vectors/chevron_left.svg';
import ArrowRight from 'vectors/chevron_right.svg';

import {
  PageNavigationContainer,
  PageNavigationScrollWrapper,
  PageNavigationAnchor,
  PageNavigationScrollButton,
} from './styled';

export const PageNavigation = ({ fields }: PageNavigationProps) => {
  const { isMobile } = useDevice();
  const scrollOffset = isMobile ? -150 : -200;
  const anchors: i.TypeSectionAnchor[] = [];

  fields.body?.forEach((section) => {
    const sectionAnchor = section.fields.sectionSettings.fields.sectionAnchor;

    if (sectionAnchor) {
      anchors.push(sectionAnchor);
    }
  });

  if (anchors.length === 0) return null;

  // Controls the left arrow button of react-horizontal-scrolling-menu
  const LeftArrow = () => {
    const { isFirstItemVisible, scrollPrev } = React.useContext(VisibilityContext);
  
    return (
      <PageNavigationScrollButton disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <ArrowLeft />
      </PageNavigationScrollButton>
    );
  };
  
  // Controls the right arrow button of react-horizontal-scrolling-menu
  const RightArrow = () => {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  
    return (
      <PageNavigationScrollButton disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <ArrowRight />
      </PageNavigationScrollButton>
    );
  };

  /*
    Use 'react-horizontal-scrolling-menu' package to hide and show arrow buttons on either side,
    and allow a scroll animation when either button is clicked.
    source: https://www.npmjs.com/package/react-horizontal-scrolling-menu
  */

  return (
    <PageNavigationContainer>
      <PageNavigationScrollWrapper>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {anchors.map((anchor) => (
            <PageNavigationAnchor
              key={anchor.sys.id}
              to={convertStringToPlainText(anchor.fields.title)}
              offset={scrollOffset}
              duration={700}
              smooth
              spy
              isDynamic
              activeClass="active"
              itemID={anchor.sys.id}
              buttonColor={anchor.fields.color}
            >
              {anchor.fields.title}
            </PageNavigationAnchor>
          ))}
        </ScrollMenu>
      </PageNavigationScrollWrapper>
    </PageNavigationContainer>
  );
};

type PageNavigationProps = {
  fields: i.TypePageGeneralFields;
};
