import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlockWithImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  ${media.tablet`
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 48px;
  `}
`;

export const ContentBlockWithImageImage = styled.div`
  width: 100%;

  ${media.tablet`
    width: calc(100% - 48px);
  `}
`;
