import * as i from 'types';
import * as React from 'react';

import { useSendConversationStarterInvite } from 'queries/sendConversationStarterInvite';
import { FormContainer } from 'common/form';
import { Modal } from 'common/interaction';
import { Heading, RichTextBlock } from 'common/typography';
import { ConversationStarterInviteBlockForm } from 'modules/conversationStarterInvite';

import { ConversationStarterInviteBlockContainer } from './styled';

export const ConversationStarterInviteBlock = ({ data }: ConversationStarterInviteBlockProps) => {
  const { mutateAsync: sendConversationStarterInvite } = useSendConversationStarterInvite();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
  const [formState, setFormState] = React.useState<'idle' | 'isSubmitting' | 'isSubmitted'>('idle');
  const defaultValues = {
    from: '',
    receiver_email: [{ value: '' }],
    add_personal_text: true,
  };

  const handleOnSubmit: i.OnSubmitProps<DefaultFormValues> = (values, methods) => {
    setFormState('isSubmitting');
    const calls: Promise<void>[] = [];

    values.receiver_email.forEach((email) => {
      const call = sendConversationStarterInvite({
        email: [email.value],
        from: values.from,
        subject: `${values.from} nodigt je uit voor de gespreksstarter`,
        standardText: data.fields.inviteEmailStandardText || '',
        personalText: values.add_personal_text ? data.fields.inviteEmailPersonalText : undefined,
      });

      calls.push(call);
    });

    Promise.all(calls).then(() => {
      setIsSuccessModalOpen(true);
      setFormState('isSubmitted');
      methods.reset(defaultValues, { keepIsSubmitted: true });
    });
  };

  return (
    <div>
      <ConversationStarterInviteBlockContainer>
        <FormContainer onSubmit={handleOnSubmit} defaultValues={defaultValues}>
          {() => <ConversationStarterInviteBlockForm data={data} formState={formState} />}
        </FormContainer>
        <Modal
          open={isSuccessModalOpen}
          setOpen={setIsSuccessModalOpen}
          title={data.fields.inviteEmailSuccessModalTitle}
        >
          <Heading as="h5" margin="0 0 24px">
            {data.fields.inviteEmailSuccessModalSubtitle}
          </Heading>
          <RichTextBlock data={data.fields.inviteEmailSuccessModal} />
        </Modal>
      </ConversationStarterInviteBlockContainer>
    </div>
  );
};

type DefaultFormValues = {
  receiver_email: { value: string }[];
  from: string;
  add_personal_text: boolean;
};

type ConversationStarterInviteBlockProps = {
  data: i.TypeHeaderConversationStarter;
};
