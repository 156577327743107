import { gql } from 'graphql-request';

export const SEND_CONVERSATION_STARTER_INVITE = gql`
  mutation sendConversationStarterInvite(
    $email: [String]!
    $from: String!
    $subject: String!
    $standardText: String!
    $personalText: String
  ) {
    sendConversationStarterInvite(
      input: {
        email: $email
        from: $from
        subject: $subject
        standardText: $standardText
        personalText: $personalText
      }
    )
  }
`;
