import styled from 'styled-components';

import { media } from 'styles/utils';

export const ConversationStarterInviteHeaderContainer = styled.div`
  padding: 48px 0;
  position: relative;

  ${media.tablet`
    padding: 96px 0;
  `}
`;

export const ConversationStarterInviteHeaderGroup = styled.div`
  display: flex;
  gap: 48px;
  flex-direction: column;

  ${media.tablet`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 48px;
  `}
`;
