import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import StarIcon from 'vectors/star.svg';

export const ReviewItemContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.desktop`
      align-items: flex-start;
    `}

    & > p {
      text-align: center;

      ${media.desktop`
        text-align: left;
      `}
    }
  `,
);

export const ReviewItemImage = styled.div`
  height: 48px;
  width: 48px;
  flex-shrink: 0;

  & > div {
    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: cover;
  }
`;

export const ReviewItemheader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 24px;

  & > h5 {
    text-align: center;

    ${media.desktop`
      text-align: left;
    `}
  }

  ${media.desktop`
    flex-direction: row;
    margin-bottom: 8px;
  `}
`;

export const ReviewItemStars = styled.div`
  display: grid;
  grid-template-columns: 24px 24px 24px 24px 24px;

  ${media.desktop`
    margin-left: 72px;
  `}
`;

export const ReviewItemStar = styled(StarIcon)<ReviewItemStarProps>(
  ({ theme, $variant }) => css`
    height: 24px;
    width: 24px;

    ${$variant === 'full' &&
    css`
      .star_svg__left_half,
      .star_svg__right_half {
        color: ${theme.colors.aquaGreen};
      }
    `}

    ${$variant === 'empty' &&
    css`
      .star_svg__left_half,
      .star_svg__right_half {
        color: ${theme.colors.aquaGreen20};
      }
    `}

    ${$variant === 'half' &&
    css`
      .star_svg__left_half {
        color: ${theme.colors.aquaGreen};
      }

      .star_svg__right_half {
        color: ${theme.colors.aquaGreen20};
      }
    `}
  `,
);

type ReviewItemStarProps = {
  $variant: 'full' | 'half' | 'empty';
};
