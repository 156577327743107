import * as i from 'types';
import * as React from 'react';

import { Image } from 'common/general';
import { Heading, Paragraph } from 'common/typography';

import {
  ReviewItemContainer,
  ReviewItemheader,
  ReviewItemImage,
  ReviewItemStar,
  ReviewItemStars,
} from './styled';

export const ReviewItem = ({ data }: ReviewItemProps) => {
  const getStarVariant = (index: number) => {
    const rating = data.fields.rating;

    if (index < Math.floor(rating)) return 'full';
    if (index === Math.floor(rating) && rating % 1 !== 0) return 'half';
    if (index >= Math.floor(rating)) return 'empty';
  };

  return (
    <ReviewItemContainer>
      <ReviewItemheader>
        {data.fields.image?.fields.file && (
          <ReviewItemImage>
            <Image
              src={data.fields.image?.fields.file?.url}
              alt={data.fields.image?.fields.description || ''}
              borderRadius={500}
            />
          </ReviewItemImage>
        )}
        <Heading as="h5" margin="0">
          {data.fields.title}
        </Heading>
      </ReviewItemheader>
      <ReviewItemStars>
        {[...Array(5)].map((u, i) => (
          <ReviewItemStar key={`review_star_${i}`} $variant={getStarVariant(i)} />
        ))}
      </ReviewItemStars>
      <Paragraph margin="24px 0 0">{data.fields.description}</Paragraph>
    </ReviewItemContainer>
  );
};

type ReviewItemProps = {
  data: i.TypeComponentReviewItem;
};
