import * as i from 'types';
import * as React from 'react';

import { HeaderReadMore } from 'common/interaction';
import { Container } from 'common/layout';
import { Heading, RichTextBlock } from 'common/typography';
import {
  ConversationStarterInviteBlock,
  ConversationStarterInviteStepper,
} from 'modules/conversationStarterInvite';

import {
  ConversationStarterInviteHeaderContainer,
  ConversationStarterInviteHeaderGroup,
} from './styled';

export const ConversationStarterInviteHeader = ({ data }: ConversationStarterInviteHeaderProps) => {
  return (
    <ConversationStarterInviteHeaderContainer>
      <Container>
        <Heading margin="0 0 48px">{data.fields.title}</Heading>
        <ConversationStarterInviteHeaderGroup>
          <div>
            <RichTextBlock data={data.fields.summaryDescription} />
            <ConversationStarterInviteStepper steps={data.fields.steps} />
          </div>
          <ConversationStarterInviteBlock data={data} />
        </ConversationStarterInviteHeaderGroup>
      </Container>
      <HeaderReadMore selector="gespreksstarterdescription" isDark scrollOffset={176} />
    </ConversationStarterInviteHeaderContainer>
  );
};

type ConversationStarterInviteHeaderProps = {
  data: i.TypeHeaderConversationStarter;
};
