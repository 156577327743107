import styled, { css } from 'styled-components';

export const QuoteBlockContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    & > svg {
      width: 72px;
      height: 48px;
      fill: ${theme.colors.aquaGreen};
    }
  `,
);
