import * as i from 'types';
import * as React from 'react';

import { isTypeComponentGridItem, isTypeComponentToolsItem } from 'services';
import { ContentBlockGridItem, ToolsItem } from 'modules/contentBlocks';

import { ContentBlockWithGridContainer } from './styled';

export const ContentBlockWithGrid = ({ data }: ContentBlockWithGridProps) => {
  return (
    <ContentBlockWithGridContainer
      amountOfColumns={
        data.fields.sectionSettings.fields.width === 'small'
          ? 1
          : data.fields.items?.[0]?.sys.contentType.sys.id === 'componentToolsItem'
          ? 2
          : 3
      }
    >
      {data.fields.items?.map((item) => (
        <React.Fragment key={item.sys.id}>
          {isTypeComponentGridItem(item) && <ContentBlockGridItem data={item} />}
          {isTypeComponentToolsItem(item) && <ToolsItem data={item} />}
        </React.Fragment>
      ))}
    </ContentBlockWithGridContainer>
  );
};

type ContentBlockWithGridProps = {
  data: i.TypeComponentContentBlockWithGrid;
};
