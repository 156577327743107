import styled, { css } from 'styled-components';

import { ImageContainer } from 'common/general/Image/styled';

export const ContentBlockWithLinksItemContainer = styled.div(
  ({ theme }) => css`
    border-bottom: ${theme.border.default};
    height: 96px;
    display: flex;
    align-items: center;
    gap: 24px;

    &:last-of-type {
      border-bottom: 0;
    }

    & a {
      font-weight: 900;
    }

    &:nth-of-type(3n + 2) a {
      color: ${theme.colors.aquaGreen};

      @media (hover: hover) {
        &:hover {
          color: ${theme.colors.aquaGreen60};
        }
      }
    }

    &:nth-of-type(3n + 3) a {
      color: ${theme.colors.pomeloRed};

      @media (hover: hover) {
        &:hover {
          color: ${theme.colors.pomeloRed60};
        }
      }
    }

    ${ImageContainer} {
      height: 48px;
      width: 48px;
    }
  `,
);
