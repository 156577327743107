import styled, { css } from 'styled-components';

export const ContactBlockContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
  `,
);

export const ContactBlockImage = styled.div`
  & img {
    border-radius: 8px 8px 0 0;
    width: 100%;
    height: auto;
  }
`;

export const ContactBlockContent = styled.div`
  padding: 24px 24px 0;
`;
