import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ConversationStarterInviteStepperStepIcon =
  styled.div<ConversationStarterInviteStepperStepIconProps>(
    ({ theme, iconUrl }) => css`
      height: 48px;
      width: 48px;
      background-color: ${theme.colors.white};
      border-radius: 100%;
      flex-shrink: 0;
      position: relative;
      background-image: ${iconUrl ? `url(${iconUrl})` : 'none'};
      background-size: contain;
      background-position: 50% 50%;

      & > svg {
        width: 48px;
        height: 48px;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        background-color: ${theme.colors.nightBlue20};
        width: 1px;
        height: 168px;
        top: 100%;

        ${media.tablet`
        height: 120px;
      `}

        ${media.desktop`
        height: 72px;
      `}
      }
    `,
  );

type ConversationStarterInviteStepperStepIconProps = {
  iconUrl?: string;
};

export const ConversationStarterInviteStepperNumber = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.nightBlue};
    height: 24px;
    width: 24px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${theme.colors.white};
    position: absolute;
    right: -12px;
    top: 50%;
  `,
);

export const ConversationStarterInviteStepperStepContent = styled.div`
  margin-left: 36px;
`;

export const ConversationStarterInviteStepperStepContainer =
  styled.div<ConversationStarterInviteStepperStepContainerProps>(
    ({ theme, isSecondLevel }) => css`
      height: 216px;
      position: relative;
      display: flex;

      ${media.tablet`
        height: 168px;
      `}

      ${media.desktop`
        height: 120px;
      `}

      &:last-of-type {
        height: auto;

        ${ConversationStarterInviteStepperStepIcon} {
          &:after {
            display: ${isSecondLevel ? 'block' : 'none'};
            height: ${isSecondLevel ? '24px' : 'auto'};
          }
        }
      }

      ${isSecondLevel &&
      css`
        margin-left: 48px;

        ${ConversationStarterInviteStepperStepIcon} {
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 24px;
            height: 1px;
            top: 24px;
            left: -24px;
            background-color: ${theme.colors.nightBlue20};
          }

          &:after {
            left: -24px;
            top: 0;
            height: 216px;

            ${media.tablet`
              height: 168px;
            `}

            ${media.desktop`
              height: 120px;
            `}
          }
        }
      `}
    `,
  );

type ConversationStarterInviteStepperStepContainerProps = {
  isSecondLevel?: boolean;
};
