import * as i from 'types';
import * as React from 'react';

import { ReviewItem } from '../ReviewItem';
import { ContentBlockWithReviewsContainer } from './styled';

export const ContentBlockWithReviews = ({ data }: ContentBlockWithReviewsProps) => {
  return (
    <ContentBlockWithReviewsContainer>
      {data.fields.reviews?.map((review) => (
        <ReviewItem data={review} key={review.sys.id} />
      ))}
    </ContentBlockWithReviewsContainer>
  );
};

type ContentBlockWithReviewsProps = {
  data: i.TypeComponentContentBlockWithReviews;
};
