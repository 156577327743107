import * as i from 'types';
import * as React from 'react';

import { Image } from 'common/general';
import { Button } from 'common/interaction';
import { Heading, RichTextBlock } from 'common/typography';

import { ContentBlockWithImageContainer, ContentBlockWithImageImage } from './styled';

export const ContentBlockWithImage = ({ data }: ContentBlockWithImageProps) => {
  return (
    <ContentBlockWithImageContainer>
      <div>
        <Heading margin="0 0 48px">{data.fields.title}</Heading>
        {data.fields.subtitle && (
          <Heading as="h4" margin="0 0 48px">
            {data.fields.subtitle}
          </Heading>
        )}
        <RichTextBlock data={data.fields.description} />
        {data.fields.button && (
          <Button href={data.fields.button.fields.link} margin="48px 0 0" buttonType="link">
            {data.fields.button?.fields.title}
          </Button>
        )}
      </div>
      {data.fields.image?.fields.file && (
        <ContentBlockWithImageImage>
          <Image
            src={data.fields.image.fields.file?.url}
            alt={data.fields.image.fields.description || data.fields.image.fields.file.fileName}
          />
        </ContentBlockWithImageImage>
      )}
    </ContentBlockWithImageContainer>
  );
};

type ContentBlockWithImageProps = {
  data: i.TypeComponentContentBlockWithImage;
};
