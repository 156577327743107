import * as i from 'types';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const PageBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PageBodySection = styled.div<PageBodySectionProps>(
  ({
    theme,
    $backgroundColor,
    isFirstOfNewColorSection,
    isLastOfCurrentColorSection,
    isOnlyContentBlock,
  }) => css`
    padding: 48px 0;
    background-color: ${theme.colors[$backgroundColor] || 'hcsBlue'};

    ${media.tablet`
      padding: 96px 0;
    `}

    ${media.desktop`
      padding-top: ${isFirstOfNewColorSection ? '128px' : '96px'};
      padding-bottom: ${isLastOfCurrentColorSection ? '128px' : '96px'};
    `}

    ${isOnlyContentBlock &&
    media.desktop`
      padding: 96px 0;
    `}
  `,
);

type PageBodySectionProps = {
  $backgroundColor: i.TypeSectionSettingsFields['backgroundColor'];
  isFirstOfNewColorSection?: boolean;
  isLastOfCurrentColorSection?: boolean;
  isOnlyContentBlock?: boolean;
};
