import * as i from 'types';
import * as React from 'react';

import { isExternalUrl } from 'services';
import { Heading, Paragraph, RichTextBlock } from 'common/typography';

import {
  ContentBlockGridItemContainer,
  ContentBlockGridItemContent,
  ContentBlockGridItemHeader,
  ContentBlockGridItemImage,
  ContentBlockGridItemOverlay,
  ContentBlockGridItemHeaderContent,
  ContentBlockGridItemDownloadButton,
} from './styled';

export const ContentBlockGridItem = ({ data }: ContentBlockWithGridItemProps) => {
  const isButtonExternalUrl =
    data.fields.button?.fields.link && isExternalUrl(data.fields.button?.fields.link);

  return (
    <ContentBlockGridItemContainer variant={data.fields.variant}>
      <ContentBlockGridItemHeader>
        <ContentBlockGridItemImage
          backgroundImageUrl={`https:${data.fields.image?.fields.file?.url}`}
        />
        <ContentBlockGridItemHeaderContent>
          <ContentBlockGridItemOverlay />
          <Heading as="h3" color="white" margin="0 0 8px">
            {data.fields.title}
          </Heading>
          {data.fields.subtitle && (
            <Paragraph margin="0" color="white">
              {data.fields.subtitle}
            </Paragraph>
          )}
        </ContentBlockGridItemHeaderContent>
      </ContentBlockGridItemHeader>
      <ContentBlockGridItemContent>
        <RichTextBlock data={data.fields.description} />
        {data.fields.button && (
          <ContentBlockGridItemDownloadButton
            href={data.fields.button.fields.link}
            buttonType="link"
            isExternalHref={Boolean(isButtonExternalUrl)}
          >
            {data.fields.button.fields.title}
          </ContentBlockGridItemDownloadButton>
        )}
      </ContentBlockGridItemContent>
    </ContentBlockGridItemContainer>
  );
};

type ContentBlockWithGridItemProps = {
  data: i.TypeComponentGridItem;
};
