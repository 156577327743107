import * as i from 'types';
import * as React from 'react';

import { Heading, Paragraph } from 'common/typography';

import {
  StatisticsItemContainer,
  StatisticsItemHeader,
  StatisticsItemBackground,
  StatisticsItemWrapper,
} from './styled';

export const StatisticsItem = ({ item }: StatisticsItemProps) => {
  return (
    <StatisticsItemWrapper>
      <StatisticsItemContainer>
        <StatisticsItemHeader>
          <div />
          <Heading as="h2" margin="0">
            {item.fields.number}
          </Heading>
          <Heading as="h1" margin="0">
            {item.fields.number}
          </Heading>
          <Heading as="h5" margin="6px 0 0 8px">
            {item.fields.unit}
          </Heading>
        </StatisticsItemHeader>
        <Paragraph align="center">{item.fields.description}</Paragraph>
      </StatisticsItemContainer>
      <StatisticsItemBackground />
    </StatisticsItemWrapper>
  );
};

type StatisticsItemProps = {
  item: i.TypeComponentStatisticsItem;
};
