import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlockWithGridContainer = styled.div<ContentBlockWithGridContainerProps>(
  ({ amountOfColumns }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    ${media.tablet`
      display: grid;
      grid-template-columns: ${`repeat(${amountOfColumns || 3}, 1fr)`};
    `}
  `,
);

type ContentBlockWithGridContainerProps = {
  amountOfColumns?: number;
};
