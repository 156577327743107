import * as i from 'types';
import { useMutation } from '@tanstack/react-query';

import { fetcher } from 'services';

import { SEND_CONVERSATION_STARTER_INVITE } from './graphql';

export const useSendConversationStarterInvite = () => {
  return useMutation(
    async (sendConversationStarterInvite: i.SendConversationStarterInviteType) =>
      await fetcher(SEND_CONVERSATION_STARTER_INVITE, { ...sendConversationStarterInvite }),
  );
};
