import * as i from 'types';
import * as React from 'react';

import { Modal } from 'common/interaction';
import { RichTextBlock } from 'common/typography';
import {
  AccordionBlockInformation,
  AccordionBlockDoDont,
  TabsVerticalBlock,
} from 'modules/contentBlocks';

import {
  AccordionBlockContainer,
  AccordionBlockHorizontalContainer,
  AccordionBlockVertical,
} from './styled';

export const AccordionBlock = ({ data }: AccordionBlockProps) => {
  const highlightedItems = data.fields.items?.filter((item) => item.fields.highlight) || [];
  const nonHighlightedItems = data.fields.items?.filter((item) => !item.fields.highlight) || [];
  const isInformationBlock = data.fields.type === 'Information';
  const isDoDontBlock = data.fields.type === "Do/Don't";
  const defaultValue = data.fields.openByDefault ? data.fields.items?.[0].sys.id : undefined;

  return (
    <AccordionBlockContainer direction={data.fields.direction}>
      <AccordionBlockVertical
        isWhite={data.fields.sectionSettings.fields.backgroundColor === 'hcsBlue'}
      >
        {isInformationBlock && highlightedItems.length > 0 && (
          <AccordionBlockInformation items={highlightedItems} defaultValue={defaultValue} />
        )}
        {isDoDontBlock && highlightedItems.length > 0 && (
          <AccordionBlockDoDont items={highlightedItems} defaultValue={defaultValue} />
        )}
      </AccordionBlockVertical>
      <AccordionBlockHorizontalContainer>
        <TabsVerticalBlock data={highlightedItems} />
      </AccordionBlockHorizontalContainer>
      {nonHighlightedItems.length > 0 && (
        <Modal
          title={data.fields.title}
          triggerText={data.fields.seeAllButtonLabel || 'Bekijk alle'}
          $withScroll
        >
          <RichTextBlock data={data.fields.description} />
          {isInformationBlock && <AccordionBlockInformation items={data.fields.items} />}
          {isDoDontBlock && (
            <AccordionBlockDoDont
              items={data.fields.items?.filter((item) => !item.fields.highlight)}
            />
          )}
        </Modal>
      )}
    </AccordionBlockContainer>
  );
};

type AccordionBlockProps = {
  data: i.TypeComponentAccordionBlock;
};
