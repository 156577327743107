import * as i from 'types';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const AccordionBlockVertical = styled.div<AccordionBlockVerticalProps>(
  ({ theme, isWhite }) => css`
    display: flex;
    flex-direction: column;
    gap: 48px;

    ${isWhite &&
    css`
      background-color: ${theme.colors.white};
      padding: 8px 24px;
      border-radius: 8px;
    `}
  `,
);

type AccordionBlockVerticalProps = {
  isWhite?: boolean;
};

export const AccordionBlockHorizontalContainer = styled.div``;

export const AccordionBlockContainer = styled.div<AccordionBlockContainerProps>(
  ({ direction }) => css`
    & > button {
      margin: 24px auto 0 0;
    }

    ${AccordionBlockVertical} {
      ${media.desktop`
        display: ${direction === 'horizontal' ? 'none' : 'flex'};
      `}
    }

    ${AccordionBlockHorizontalContainer} {
      display: none;

      ${media.desktop`
        display: ${direction === 'horizontal' ? 'block' : 'none'};
      `}
    }
  `,
);

type AccordionBlockContainerProps = {
  direction?: i.TypeComponentAccordionBlockFields['direction'];
};
