import * as i from 'types';
import * as React from 'react';

import { HeaderReadMore } from 'common/interaction';
import { Container } from 'common/layout';
import { Heading, RichTextBlock } from 'common/typography';
import { ThemeVideoBlock } from 'modules/theme';

import {
  ThemeHeaderDescription,
  ThemeHeaderContent,
  ThemeHeaderMeta,
  ThemeHeaderVideoDesktop,
} from './styled';

export const ThemeHeader = ({ data, firstSection }: PageHeaderProps) => {
  return (
    <Container>
      <ThemeHeaderContent>
        <ThemeHeaderMeta>
          <Heading margin="0">{data?.fields.title}</Heading>
          <Heading as="h3" margin="0">
            {data?.fields.subtitle}
          </Heading>
          <ThemeHeaderDescription>
            <RichTextBlock data={data?.fields.description} />
          </ThemeHeaderDescription>
        </ThemeHeaderMeta>
        <ThemeHeaderVideoDesktop>
          {data?.fields.videoId && <ThemeVideoBlock videoId={data?.fields.videoId} />}
        </ThemeHeaderVideoDesktop>
        <HeaderReadMore selector={firstSection || ''} isDark />
      </ThemeHeaderContent>
    </Container>
  );
};

type PageHeaderProps = {
  data?: i.TypeHeaderTheme;
  firstSection?: string;
};
