import * as i from 'types';
import styled, { css } from 'styled-components';
import { Link } from 'react-scroll'

import { media } from 'styles/utils';

export const PageNavigationContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.nightBlue};
    height: 48px;
    position: sticky;
    top: 76px;
    z-index: 8;

    ${media.tablet`
      top: 80px;
    `}
  `,
);

export const PageNavigationScrollWrapper = styled.div`
  /* Css to style 'react-horizontal-scrolling-menu' package elements.
    Cleaned up as much as I could and removed unused styling. */
  & .react-horizontal-scrolling-menu--inner-wrapper {
    display:flex;
    width: 100%;

    ${media.desktop`
      padding: 0 48px;
      margin: 0 auto;
      max-width: 960px;
    `}

    & > div {
      display:flex;
    }
  }

  & .react-horizontal-scrolling-menu--scroll-container {
    height: 48px;
    align-items: center;
    gap: 12px;
    overflow-y: hidden;

    ${media.desktop`
      gap: 24px;
    `}
  }
`;

export const PageNavigationAnchor = styled(Link)<PageNavigationAnchorProps>(
  ({ theme, buttonColor }) => css`
    border: 0;
    padding: 0;
    background-color: transparent;
    font-size: 16px;
    font-weight: 900;
    font-family: ${theme.fonts.lato};
    color: ${theme.colors.white};
    cursor: pointer;

    &.active {
      color: ${theme.colors[buttonColor || 'babyBlue']};
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.6;
      }
    }
  `,
);

type PageNavigationAnchorProps = {
  buttonColor: i.TypeSectionAnchorFields['color'];
};

export const PageNavigationScrollButton = styled.button(
  ({ theme }) => css`
    background-color: ${theme.colors.nightBlue};
    border: none;

    & svg {
      width: 24px;
      height: 24px;
      fill: white;
      transition: .2s ease;
    }

    &:disabled {
      & svg {
        fill: transparent;
      }
    }

    ${media.desktop`
      display: none;
    `}
  `,
);
