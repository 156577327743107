import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const StatisticsItemBackground = styled.div(
  ({ theme }) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    left: -24px;
    bottom: -24px;
    background-color: ${theme.colors.babyBlue};
    border-radius: 8px;
  `,
);

export const StatisticsItemWrapper = styled.div(
  ({ theme }) => css`
    position: relative;
    margin: 0 0 24px 24px;

    &:nth-of-type(3n + 2) ${StatisticsItemBackground} {
      background-color: ${theme.colors.aquaGreen};
    }

    &:nth-of-type(3n + 3) ${StatisticsItemBackground} {
      background-color: ${theme.colors.pomeloRed};
    }
  `,
);

export const StatisticsItemContainer = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border: ${theme.border.default};
    border-radius: 8px;
    padding: 24px;
    position: relative;
    z-index: 1;
    height: 100%;
  `,
);

export const StatisticsItemHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-bottom: 24px;

  & h1 {
    display: none;
  }

  ${media.tablet`
    & h1 {
      display: block;
    }

    & h2 {
      display: none;
    }
  `}
`;
