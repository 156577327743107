import * as React from 'react';

import { ThemeVideoBlockVideoContainer, ThemeVideoBlockVideo } from './styled';

export const ThemeVideoBlock = ({ videoId }: ThemeVideoBlockProps) => {
  return (
    <ThemeVideoBlockVideoContainer>
      <ThemeVideoBlockVideo
        src={`https://player.vimeo.com/video/${videoId}?h=5a6a531119&color=E2EEFF&title=0&byline=0&portrait=0" frameborder="0" allow="autoplay; fullscreen;" allowfullscreen>`}
      />
    </ThemeVideoBlockVideoContainer>
  );
};

type ThemeVideoBlockProps = {
  videoId: string;
};
