import * as i from 'types';
import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Button } from 'common/interaction';

export const ContentBlockGridItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px;
  position: relative;
  height: 136px;

  ${media.tablet`
    height: 168px;
  `}

  ${media.desktop`
    height: 136px;
  `}
`;

export const ContentBlockGridItemOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(98deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  z-index: -1;
`;

export const ContentBlockGridItemImage = styled.div<ContentBlockGridItemImageProps>(
  ({ backgroundImageUrl }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-image: url(${backgroundImageUrl});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  `,
);

type ContentBlockGridItemImageProps = {
  backgroundImageUrl: string;
};

export const ContentBlockGridItemHeaderContent = styled.div`
  z-index: 1;
`;

export const ContentBlockGridItemContent = styled.div`
  padding: 16px 24px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 136px);

  ${media.tablet`
    height: calc(100% - 168px);
  `}

  ${media.desktop`
    height: calc(100% - 136px);
  `}
`;

export const ContentBlockGridItemDownloadButton = styled(Button)`
  transition: 0.15s ease;
  margin-top: 16px;

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
`;

export const ContentBlockGridItemContainer = styled.div<ContentBlockGridItemContainerProps>(
  ({ theme, variant }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    width: 100%;

    ${media.tablet`
      grid-column: auto / span 1;
    `}

    ${(variant === 'medium' || variant === 'large') &&
    css`
      ${ContentBlockGridItemHeaderContent} {
        display: block;
      }

      ${media.tablet`
        display: flex;

        ${ContentBlockGridItemHeaderContent} {
          display: none;
        }

        ${ContentBlockGridItemOverlay},
        ${ContentBlockGridItemImage} {
          border-top-right-radius: 0;
          border-bottom-left-radius: 8px;
        }

        ${ContentBlockGridItemContent} {
          height: 100%;
          justify-content: flex-start;
          gap: 0;
        }
      `}
    `}

    ${variant === 'medium' &&
    css`
      ${media.tablet`
        grid-column: auto / span 2;

        ${ContentBlockGridItemHeader} {
          height: 100%;
          width: 50%;
        }

        ${ContentBlockGridItemContent} {
          width: 50%;
        }
      `}
    `}

    ${variant === 'large' &&
    css`
      ${media.tablet`
         grid-column: auto / span 3;

        ${ContentBlockGridItemHeader} {
          height: 100%;
          width: 66.66%
        }

        ${ContentBlockGridItemOverlay},
        ${ContentBlockGridItemImage} {
          border-top-right-radius: 0;
          border-bottom-left-radius: 8px;
        }

        ${ContentBlockGridItemContent} {
          width: 33.33%;
        }
      `}
    `}

    &:nth-of-type(3n + 1) {
      ${ContentBlockGridItemDownloadButton} {
        background-color: ${theme.colors.babyBlue};
      }
    }

    &:nth-of-type(3n + 2) {
      ${ContentBlockGridItemDownloadButton} {
        background-color: ${theme.colors.aquaGreen};
      }
    }

    &:nth-of-type(3n + 3) {
      ${ContentBlockGridItemDownloadButton} {
        background-color: ${theme.colors.pomeloRed};
      }
    }
  `,
);

type ContentBlockGridItemContainerProps = {
  variant: i.TypeComponentGridItemFields['variant'];
};
