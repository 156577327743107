import * as i from 'types';
import * as React from 'react';

import ApprovedIcon from 'vectors/approved.svg';
import AttentionIcon from 'vectors/attention.svg';
import BlockIcon from 'vectors/block.svg';
import { Accordion } from 'common/interaction';

export const AccordionBlockDoDont = ({ items, defaultValue }: AccordionBlockDoDontProps) => {
  return (
    <Accordion.Root defaultValue={defaultValue}>
      {items?.map((item, index) => (
        <Accordion.Item
          key={item.sys.id}
          isRichTextContent
          index={index}
          icon={
            item.fields.icon === 'Approved' ? (
              <ApprovedIcon />
            ) : item.fields.icon === 'Block' ? (
              <BlockIcon />
            ) : item.fields.icon === 'Attention' ? (
              <AttentionIcon />
            ) : (
              <></>
            )
          }
          item={{
            title: item.fields.title,
            description: item.fields.description,
            id: item.sys.id,
            subtitle: item.fields.subtitle,
            brandingColor:
              item.fields.icon === 'Approved'
                ? 'green'
                : item.fields.icon === 'Block'
                ? 'red'
                : 'blue',
          }}
        />
      ))}
    </Accordion.Root>
  );
};

type AccordionBlockDoDontProps = {
  items: i.TypeComponentAccordionBlockFields['items'];
  defaultValue?: string;
};
