import * as i from 'types';
import * as React from 'react';

import { Image } from 'common/general';
import { Modal, TextButton } from 'common/interaction';
import { Paragraph } from 'common/typography';
import { ConversationStartInvitePreview } from 'modules/conversationStarterInvite';

import {
  ConversationStarterInviteStepperStepContainer,
  ConversationStarterInviteStepperStepContent,
  ConversationStarterInviteStepperStepIcon,
  ConversationStarterInviteStepperNumber,
} from './styled';

export const ConversationStarterInviteStepperStep = ({
  step,
  index,
}: ConversationStarterInviteStepperStepProps) => {
  const [isPreviewOpen, setIsPreviewOpen] = React.useState(false);

  return (
    <ConversationStarterInviteStepperStepContainer isSecondLevel={step.fields.type === 'employee'}>
      <ConversationStarterInviteStepperStepIcon iconUrl={step.fields.icon?.fields.file?.url}>
        <ConversationStarterInviteStepperNumber>{index}</ConversationStarterInviteStepperNumber>
      </ConversationStarterInviteStepperStepIcon>
      <ConversationStarterInviteStepperStepContent>
        <Paragraph weight={900} margin="0">
          {step.fields.title}
        </Paragraph>
        <Paragraph margin="0" color="nightBlue60">
          {step.fields.subtitle}
        </Paragraph>
        {step.fields.action && (
          <TextButton onClick={() => setIsPreviewOpen(true)}>
            {step.fields.actionLabel || 'Bekijk voorbeeld'}
          </TextButton>
        )}
      </ConversationStarterInviteStepperStepContent>
      {step.fields.action === 'email_example' ? (
        <ConversationStartInvitePreview isOpen={isPreviewOpen} setOpen={setIsPreviewOpen} />
      ) : (
        <Modal open={isPreviewOpen} setOpen={setIsPreviewOpen} title="Voorbeeld">
          {step.fields.exampleImage?.fields.file && (
            <Image
              src={step.fields.exampleImage?.fields.file?.url}
              alt={step.fields.exampleImage?.fields.title || ''}
            />
          )}
        </Modal>
      )}
    </ConversationStarterInviteStepperStepContainer>
  );
};

type ConversationStarterInviteStepperStepProps = {
  step: i.TypeComponentConversationStarterStepperItem;
  index: number;
};
