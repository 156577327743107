import styled from 'styled-components';

import { media } from 'styles/utils';

export const ContentBlockWithColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;

  ${media.tablet`
    grid-template-columns: repeat(2, calc(50% - 24px));
  `}
`;
