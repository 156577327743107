import * as i from 'types';
import * as React from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';

import { validation } from 'services';
import CheckIcon from 'vectors/check.svg';
import CrossIcon from 'vectors/cross.svg';
import MailIcon from 'vectors/mail.svg';
import PersonIcon from 'vectors/person.svg';
import PlusIcon from 'vectors/plus.svg';
import { Input, InputWithAction } from 'common/form';
import { Button, TextButton } from 'common/interaction';
import { Heading, Paragraph } from 'common/typography';

import { ConversationStarterInviteBlockFormButtons } from './styled';

export const ConversationStarterInviteBlockForm = ({
  data,
  formState,
}: ConversationStarterInviteBlockFormProps) => {
  const {
    register,
    control,
    formState: { errors, isDirty },
  } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'receiver_email',
  });
  const isSubmitted = formState === 'isSubmitted';
  const isButtonInSentState = isSubmitted && !isDirty;

  return (
    <>
      <Heading as="h4" margin="0 0 24px">
        {isSubmitted ? data.fields.inviteEmailTitleSuccess : data.fields.inviteEmailTitle}
      </Heading>
      <Paragraph margin="0 0 24px">
        {isSubmitted
          ? data.fields.inviteEmailDescriptionSuccess
          : data.fields.inviteEmailDescription}
      </Paragraph>
      <Input icon={<PersonIcon />} placeholder="Je eigen naam" {...register('from')} />
      {fields.map((field, index) => {
        const inputProps = {
          placeholder: 'Zakelijk e-mailadres van medewerker',
          key: field.id,
        };

        if (index === 0) {
          return (
            <Input
              icon={<MailIcon />}
              error={errors.receiver_email?.[0]?.value}
              {...inputProps}
              {...register(`receiver_email.${index}.value`, {
                ...validation.required,
                ...validation.email,
              })}
            />
          );
        }

        return (
          <Controller
            control={control}
            key={field.id}
            name={`receiver_email.${index}.value`}
            rules={{ ...validation.email }}
            render={({ field: { onChange, name, value } }) => (
              <InputWithAction
                error={errors.receiver_email?.[index]?.value}
                autoFocus
                actionIcon={<CrossIcon />}
                action={() => remove(index)}
                icon={<MailIcon />}
                {...inputProps}
                {...{ onChange, name, value }}
              />
            )}
          />
        );
      })}
      <TextButton
        margin="0 0 24px"
        onClick={() =>
          append({ value: '' }, { focusName: `receiver_email.${fields.length + 1}.value` })
        }
        type="button"
        gap={24}
      >
        <PlusIcon /> {data.fields.inviteEmailAddEmployeeLabel || 'Nog een medewerker toevoegen'}
      </TextButton>
      <ConversationStarterInviteBlockFormButtons>
        <Button
          type="submit"
          icon={isButtonInSentState ? <CheckIcon /> : null}
          variant={isButtonInSentState ? 'success' : 'primary'}
          isLoading={formState === 'isSubmitting'}
        >
          {isButtonInSentState
            ? data.fields.inviteEmailSendSuccessLabel || 'Verzonden'
            : data.fields.inviteEmailSendLabel || 'Verzenden'}
        </Button>
      </ConversationStarterInviteBlockFormButtons>
    </>
  );
};

type ConversationStarterInviteBlockFormProps = {
  data: i.TypeHeaderConversationStarter;
  formState: 'idle' | 'isSubmitting' | 'isSubmitted';
};
